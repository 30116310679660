import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/Router'
import qs from 'qs'
import http from '@/libs/http'
import {
    Dialog
} from 'vant'
import {
    isWexin
} from '../libs/tools'

Vue.use(Vuex)
const localsr = localStorage.getItem('75popState') ? JSON.parse(localStorage.getItem('75popState')) : null;
const state = {
    ajaxLoading: false,
    pageTitle: '起舞网',
    showTabbar: true,
    tabIndex: 'index',
    showLogin: false,
    beforeLoginPath: '',
    catTree: null,
    fabs: {
        index: {
            show: true,
            icon: 'wap-home',
            name: '首页',
            to: 'index'
        },
        search: {
            show: true,
            icon: 'bars',
            name: '列表',
            to: 'goodsList'
        },
        user: {
            show: true,
            icon: 'user-o',
            name: '我的',
            to: 'user'
        },
    },
    history: [],
    user: null,
    openId: '',
    cart: null,
    isWeixin: false,
    isMobile: false,
    animateDirection: 'left', // 当前页面是前进还是后退，控制切换页面的动画方向
    routerHistory: [], // 路由栈
    pageDataReload: { // 各页面的数据
        index: 0, // 首页数据
        info: 0, // 教材详情页数据 
        list: 0, // 列表页数据
    },
}

const getters = {}

const mutations = {
    reloadPageData(state, key) {
        state.pageDataReload[key]++
    },
    setFabShow(state, payload) {
        state.fabs[payload.key] = payload.show;
    },
    setTabIndex(state, payload) {
        state.tabIndex = payload;
    },
    setIsWeixin(state) {
        state.isWeixin = isWexin();
    },
    setLoading(state, status) {
        state.ajaxLoading = !!status;
    },
    setShowLogin(state, status) {
        this.commit('setUser');
        router.push({
            name: 'login'
        })
        state.showLogin = status;
    },
    setBeforeLogin(state, payload) {
        state.beforeLoginPath = payload
    },
    // 添加或删除路由栈
    setRouterHistory(state, path) {
        const index = state.routerHistory.indexOf(path.to + ',' + path.from);
        if (index > -1) {
            state.routerHistory.splice(index, 1);
            state.animateDirection = 'right';
        } else {
            state.routerHistory.push(path.from + ',' + path.to);
            state.animateDirection = 'left';
        }
    },
    setPageTitle(state, payload) {
        const title = typeof(payload) === 'string' ? payload : '起舞网'
        document.title = title + ' - 起舞网'
        state.pageTitle = title
    },
    setShowTabbar(state, status) {
        state.showTabbar = !!status;
    },
    setCart(state, payload) {
        state.cart = payload || null;
    },
    setOpenId(state, payload) {
        state.openId = payload.openId;
    },
    setUser(state, payload) {
        if (payload && payload.user_id) {
            state.user = payload;
        } else {
            state.user = null;
            sessionStorage.removeItem('75popState');
        }
    },
    setCatTree(state, payload) {
        state.catTree = payload;
    },
    setHistory(state, payload) {
        state.history = payload || [];
    },
    setIsMobile(state, payload) {
        state.isMobile = payload || false;
    }
}

const actions = {
    getInit({
        commit,
    }) {
        http.get('init').then(res => {
            if (res && res.code === 1) {
                const {
                    navs,
                    user
                } = res.data;
                commit('setUser', user);
                commit('setCatTree', navs);
            }
        }).catch(err => {
            console.log(err);
            Dialog.alert({
                message: '网络错误'
            });
        })
    },
    getHistory({
        commit
    }) {
        http.get('e/?m=gethistory').then(res => {
            if (res.code) return;
            commit('setHistory', res.data.list);
        })
    },
    clearHistory({
        commit
    }) {
        http.get('e/?m=clearhistory').then(res => {
            commit('setHistory', []);
        })
    },
    request({
        state
    }, {
        url,
        method,
        params
    }) {
        let key = url + (params ? JSON.stringify(params) : '');
        var RespCache = sessionStorage.getItem('RespCache1') || '{}';
        RespCache = JSON.parse(RespCache);
        if (RespCache[key]) {
            const value = RespCache[key];
            console.log('取缓存数据：', value)
            return new Promise(resove => {
                resove(value)
            })
        } else {
            return http[method](url, params).then(res => {
                if (res.code !== 0) {
                    log(RespCache, res);
                    RespCache[key] = res;
                    sessionStorage.setItem('RespCache', JSON.stringify(RespCache));
                }
                return res
            })
        }
    },
}

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions
})