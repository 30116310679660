import Vue from 'vue'
import Router from 'vue-router'
import routes from './routers'
import store from '../store'
import { Toast } from 'vant'
import { weixinInit, isWexin } from '../libs/tools'
/** 避免重复跳转到当前路径时报错 start */
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
        return originalPush.call(this, location).catch(err => err)
    }
    /** 避免重复跳转到当前路径时报错 end */

Vue.use(Router)

const router = new Router({
    routes,
    base: process.env.NODE_ENV === 'development' ? '/' : '/m/',
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    // 判断是否是微信环境
    store.commit('setIsWeixin');
    // 设置tabbar标签
    if (to.meta) {
        const { title } = to.meta;
        // 设置标题
        if (title) {
            store.commit('setPageTitle', title)
        }
    }
    if (to.name === 'login') {
        const isLogin = store.state.user && store.state.user.user_id;
        if (isLogin) {
            Toast({ message: '您已登陆' });
            router.replace({ path: from.fullPath });
        } else store.commit('setBeforeLogin', from.fullPath);
    }
    // 执行并保存微信code参数
    if (isWexin()) {
        weixinInit(next);
    }
    // 构造路由栈，用于判断是返回还是新建页面，生成切换动画（放在所有判断的最后）
    store.commit('setRouterHistory', {
        from: from.fullPath,
        to: to.fullPath
    })
    next()
})

router.afterEach(to => {

})

export default router