import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './Router'
import http from './libs/http'
import {
	Mixins
} from './libs/util'
import './assets/css/base.css'
import 'lib-flexible' //引入rem自适应
import {
	Icon,
	Button,
	Cell,
	CellGroup
} from 'vant'
// import Vconsole from 'vconsole';
// const vConsole = new Vconsole();
// Vue.use(vConsole)

Vue.use(Icon)
Vue.use(CellGroup)
Vue.use(Cell)
Vue.use(Button)

Vue.config.productionTip = false
Vue.prototype.HOST = 'http://75pop.com/e/'
Vue.prototype.$store = store
Vue.prototype.$http = http
window.log = console.log;

Vue.mixin(Mixins);
window.vm = new Vue({
	store,
	router,
	render: h => h(App),
}).$mount('#app')
