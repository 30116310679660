export default [{
        path: '/',
        redirect: '/index.html'
    },
    {
        path: '/index',
        redirect: '/index.html'
    }, {
    //     path: '/index.html/:type?/:page?',
    //     name: 'index',
    //     meta: { title: '首页', tab: 'index' },
    //     component: () =>
    //         import ('@/pages/index.vue')
    // }, {
        path: '/login.html',
        name: 'login',
        meta: { title: '登录', tab: 'user' },
        component: () =>
            import ('@/pages/login/index.vue')
    }, {
        path: '/search.html',
        name: 'search',
        meta: { title: '搜索', tab: 'search' },
        component: () =>
            import ('@/pages/category/search.vue')
    }, {
        path: '/category-:classid',
        name: 'category',
        meta: { title: '资料分类', tab: 'search' },
        component: () =>
            import ('@/pages/category/index.vue')
    }, {
        path: '/item-:id(\\d+).html',
        name: 'show',
        props: true,
        meta: { title: '资料详情', tab: 'search' },
        component: () =>
            import ('@/pages/show/info.vue')
    }, {
        path: '/play-:classid-:id',
        name: 'play',
        props: true,
        meta: { title: '在线播放', tab: 'search' },
        component: () =>
            import ('@/pages/video/play.vue')
    }, {
        path: '/index.html',
        name: 'goodsList',
        props: true,
        meta: { title: '资料列表', tab: 'search' },
        component: () =>
            import ('@/pages/show/index.vue')
    }, {
        path: '/videos-:id-:p?',
        name: 'videoList',
        props: true,
        meta: { title: '视频列表', tab: 'video' },
        component: () =>
            import ('@/pages/video/index.vue')
    }, {
        path: '/flow',
        name: 'flow',
        meta: { title: '购物车', tab: 'flow' },
        component: () =>
            import ('@/pages/flow/index.vue')
    }, {
        path: '/flow/checkout',
        name: 'checkout',
        meta: { title: '确认订单', tab: 'flow' },
        component: () =>
            import ('@/pages/flow/checkout.vue')
    }, {
        path: '/flow/done',
        name: 'done',
        component: () =>
            import ('@/pages/flow/done.vue')
    }, {
        path: '/report-:classid-:id',
        name: 'report',
        props: true,
        meta: { title: '提交错误报告', tab: 'user' },
        component: () =>
            import ('@/pages/show/report.vue')
    }, {
        path: '/user.html',
        name: 'user',
        meta: { title: '用户中心', tab: 'user' },
        component: () =>
            import ('@/pages/user/index.vue')
    },
    {
        path: '/user',
        name: 'user_main',
        component: () =>
            import ('@/pages/user/main.vue'),
        children: [{
            path: '/user/info.html',
            name: 'userinfo',
            meta: { title: '用户信息', tab: 'user' },
            component: () =>
                import ('@/pages/user/Info.vue')
        }, {
            path: '/user/get_pass.html',
            name: 'getPassword',
            meta: { title: '找回密码', tab: 'user' },
            component: () =>
                import ('@/pages/login/getPassword/index.vue')
        }, {
            path: '/user/change-password',
            name: 'changePassword',
            meta: { title: '修改登录密码', tab: 'user' },
            component: () =>
                import ('@/pages/user/changePassword.vue')
        }, {
            path: '/user/orders.html',
            name: 'orders',
            meta: { title: '我的订单', tab: 'user' },
            component: () =>
                import ('@/pages/user/Orders.vue')
        }, {
            path: '/user/order-:id.html',
            name: 'order',
            meta: { title: '订单详情', tab: 'user' },
            hidden: true,
            props: true,
            component: () =>
                import ('@/pages/user/Order.vue')
        }, {
            path: '/user/his.html',
            name: 'hist',
            meta: { title: '历史记录', tab: 'user' },
            component: () =>
                import ('@/pages/user/History.vue')
        }, {
            path: '/user/fav.html',
            name: 'collection',
            meta: { title: '我的收藏', tab: 'user' },
            component: () =>
                import ('@/pages/user/Collection.vue')
        }, {
            path: '/user/recharge.html',
            name: 'buyfen',
            meta: { title: '在线充值', tab: 'user' },
            component: () =>
                import ('@/pages/user/Buyfen.vue')
        }, {
            path: '/user/address',
            name: 'address',
            meta: { title: '收货地址', tab: 'user' },
            component: () =>
                import ('@/pages/user/Address.vue')
        }, {
            path: '/user/recharge_logs.html',
            name: 'buybak',
            meta: { title: '充值记录', tab: 'user' },
            component: () =>
                import ('@/pages/user/Buybak.vue')
        }, {
            path: '/user/downbak',
            name: 'downbak',
            meta: { title: '消费记录', tab: 'user' },
            component: () =>
                import ('@/pages/user/Downbak.vue')
        }, ]
    },
    {
        path: '/pay',
        name: 'wxpay',
        meta: { title: '微信付款', tab: 'user' },
        props: true,
        component: () =>
            import ('@/pages/pay/wxpay.vue')
    }, {
        path: '/kefu',
        name: 'kefu',
        meta: { title: '客服联系方式', tab: 'user' },
        component: () =>
            import ('@/pages/user/Kefu.vue')
    }, {
        path: '/error-500',
        name: 'error-500',
        meta: { title: '网络错误-500' },
        component: () =>
            import ('@/pages/error/error500.vue')
    }, {
        path: '*',
        name: 'error-404',
        meta: { title: '页面丢失-404错误' },
        component: () =>
            import ('@/pages/error/error404.vue')
    }
]