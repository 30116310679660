<template>
  <div id="app">
    <van-nav-bar
      v-show="!isWeixin"
      :left-arrow="$route.name !== 'index'"
      :title="state.pageTitle"
      @click-left="onClickLeft"
    />
    <transition :name="animateDirection">
      <router-view></router-view>
    </transition>

    <van-tabbar v-model="tabbarActive" v-show="showTabbar" route style="max-width: 640px">
      <van-tabbar-item icon="home-o" name="index" :to="{ name: 'goodsList' }">首页</van-tabbar-item>
      <van-tabbar-item icon="gem-o" :to="{ name: 'buyfen' }">充值</van-tabbar-item>
      <van-tabbar-item icon="balance-list-o" :to="{ name: 'downbak' }">记录</van-tabbar-item>
      <van-tabbar-item icon="user-o" name="user" :to="{ name: 'user' }">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast, NavBar, Tabbar, TabbarItem } from "vant";
Vue.use(NavBar)
  .use(Tabbar)
  .use(TabbarItem);

export default {
  name: "app",
  data() {
    return {
      toast: null
    };
  },
  watch: {
    ajaxLoading(loading) {
      if (loading) {
        this.toast = Toast.loading({
          message: "加载中...",
          forbidClick: true,
          loadingType: "spinner",
          duration: 0
        });
      } else {
        this.toast.clear();
      }
    },
    $route: function(route) {
      this.$store.commit("setPageTitle", route.meta.title);
      this.backtop();
    }
  },
  computed: {
    ajaxLoading() {
      return this.$store.state.ajaxLoading;
    },
    tabbarActive: {
      get() {
        return this.$store.state.tabIndex;
      },
      set(index) {
        this.$store.commit("setTabIndex", index);
      }
    },
    showTabbar() {
      return this.$store.state.showTabbar;
    },
    cartNumber() {
      return this.$store.state.cart ? this.$store.state.cart.total : 0;
    },
    animateDirection() {
      return this.$store.state.animateDirection || "left";
    },
    isWeixin() {
      // return this.$store.state.isWeixin;
      return true;
    },
    state() {
      return this.$store.state;
    }
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight() {
      this.$router.push({
        name: "kefu"
      });
    }
  },
  created() {
    this.$store.commit("setIsMobile", true);
    this.$store.dispatch("getInit");
  },
  mounted() {
    const state = this.$store.state;
    window.addEventListener("pagehide", () => {
      sessionStorage.setItem("75popState", JSON.stringify(state));
    });
    window.addEventListener("onbeforeunload", () => {
      sessionStorage.setItem("75popState", JSON.stringify(state));
    });
  }
};
</script>

<style>
body {
  background-color: #e3e3e3;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #e3e3e3;
  max-width: 640px;
  min-height: 100vh;
  padding-bottom: 60px;
  overflow: hidden;
}
.page {
  background-color: #e3e3e3;
  width: 100vw;
  min-height: 100vh;
  max-width: 100%;
}

.to-top {
  position: fixed;
  bottom: 20%;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #dddddd;
  width: 40px;
  height: 40px;
  line-height: 44px;
  font-size: 20px;
  text-align: center;
  border-radius: 50px;
  z-index: 9999;
}

.popup {
  height: 80%;
}
.right-enter {
  transform: translate3d(-100%, 0, 0);
}

.right-leave {
  transform: translate3d(-100%, 0, 0);
}

.left-enter {
  transform: translate3d(100%, 0, 0);
}

.left-enter-active,
.left-leave-active,
.right-enter-active,
.right-leave-active {
  will-change: transform;
  transition: all 0.6s;
  position: absolute;
  max-width: 640px;
}

/** 播放器样式 */
.video-length {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 3px 6px;
  background-color: #333333;
  color: #fff;
}

.video_points {
  position: absolute;
  right: 10px;
  bottom: 9px;
  color: red;
}

.video-status {
  position: absolute;
  right: 10px;
  top: 7px;
  color: red;
}

.video-header {
  padding: 5px 15px;
}

.video-header .video-title {
  font-size: 14px;
  padding-bottom: 15px;
}

.video-header .video-desc {
  color: #aaaaaa;
  padding-bottom: 10px;
}

.video-player .video-js .vjs-big-play-button {
  font-size: 2.5em;
  line-height: 2.3em;
  height: 2.5em;
  width: 2.5em;
  -webkit-border-radius: 2.5em;
  -moz-border-radius: 2.5em;
  border-radius: 2.5em;
  background-color: #73859f;
  background-color: rgba(115, 133, 159, 0.5);
  border-width: 0.15em;
  margin-top: -1.25em;
  margin-left: -1.75em;
}

/* 中间的播放箭头 */
.video-player .vjs-big-play-button .vjs-icon-placeholder {
  font-size: 1.63em;
}

/* 加载圆圈 */
.video-player .vjs-loading-spinner {
  font-size: 2.5em;
  width: 2em;
  height: 2em;
  border-radius: 1em;
  margin-top: -1em;
  margin-left: -1.5em;
}

.video-player .video-js .vjs-time-control {
  display: block;
}

.video-player .video-js .vjs-remaining-time {
  display: none;
}

.van-sticky--fixed {
  max-width: 640px;
}
</style>
