import axios from "axios";
import qs from "qs";
import store from "@/store";
import router from "@/Router";
import { getToken } from "./tools";
import { Toast, Dialog } from "vant";

axios.defaults.baseURL = "/api/";

const http = axios.create({
    timeout: 20000,
    withCredentials: true, // 允许携带token ,这个是解决跨域产生的相关问题
});

http.interceptors.request.use(
    (config) => {
        Toast.loading({duration: 0});
        config.headers = {
            'XX-Token': getToken(),
            'XX-Device-Type': 'mobile',
            'Content-Type': 'application/x-www-form-urlencoded',
        };
        config.params = Object.assign({}, config.params);
        if (config.params.loading) {
            delete(config.params.loading);
            store.commit("setLoading", true);
        }
        return config;
    },
    (error) => {
        log(error);
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    (response) => {
        store.commit("setLoading", false);
        const res = response.data;
        const code = res.code;
        switch (code) {
            case 1:
                if (res.msg && res.msg.length <= 5) {
                    Toast.success({ message: res.msg, className: "toast" });
                } else if (res.msg) {
                    Toast({ message: res.msg, className: "toast" });
                }
                return res;

            case 0:
                Toast.fail(res.msg);
                return res;

            case 2:
                Dialog.alert({
                    message: res.msg,
                    center: true,
                }).then(() => {
                    if (res.data === "back") router.back();
                });
                return res;

            case 10001:
                // 如果没有登录，跳转到登录页面
                Toast({ message: res.msg });
                router.push({ name: "login" });
                break;

            default:
                return res;
        }
    },
    (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    router.push({
                        name: "login",
                    });
                    break;
                case 500: // **未找到页面
                    router.push({
                        name: "error-500",
                    });
                    break;
                default:
                    router.push({
                        name: "error-404",
                    });
                    break;
            }
            return Promise.reject(error.response.data);
        } else {
            let config = error.config;
            // If config does not exist or the retry option is not set, reject
            if (!config) {
                store.commit("setLoading", false);

                Dialog.alert({ message: "网络超时" }).then(() => {
                    return Promise.reject(error);
                });
            } else if (!config.retry) {
                config.retry = 5;
                config.retryDelay = 1000;
                config.__retryCount = 0;
            }

            if (config.__retryCount >= config.retry) {
                store.commit("setLoading", false);
                Toast.fail("网络超时");
                return Promise.reject(error);
            } else {
                config.__retryCount += 1;
                config.timeout = config.__retryCount * config.timeout;
                log("网络重复请求", config.__retryCount, "次", config.timeout);
                let backoff = new Promise((resolve) => {
                    setTimeout(() => {
                        resolve();
                    }, config.retryDelay || 1);
                });

                return backoff.then(() => {
                    return http.request(config);
                });
            }
        }
    }
);

export function get(hasParamUrl, params) {
    if (hasParamUrl == null) return;
    var param = params ? {
        params: params,
    } : {
        params: "",
    };
    return http.get(hasParamUrl, param);
}

export function post(requestUrl, params) {
    var param = qs.stringify(params);
    return http.post(requestUrl, param);
}

export function postStringParamData(requestUrl, params) {
    return http.post(requestUrl, JSON.stringify(params));
}

export function postFile(uploadFileUrl, formData, callback) {
    let config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    };
    return http.post(uploadFileUrl, formData, config);
}

export default {
    http,
    get,
    post,
    postStringParamData,
    postFile,
};