export const Mixins = {
    data() {
        return {
            payLoading: false,
        };
    },
    computed: {
        isLogin() {
            return this.$store.state.user && this.$store.state.user.user_id > 0;
        },
    },
    methods: {
        setData(obj) {
            if (typeof obj != "object") return;
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    this[key] = obj[key];
                }
            }
        },
        showInfo(params) {
            const query = {};
            if (this.$route.query.kw) query.kw = this.$route.query.kw;
            this.$router.push({ name: 'show', params, query });
        },
        showClass(classid) {
            this.$router.push({ name: "category", params: { classid } });
        },
        request(params) {
            return this.$store.dispatch("request", params);
        },
        fava(item) {
            this.$http.post("change_fav", { id: item.id }).then((res) => {
                if (res.code === 1) {
                    item.hasFav = res.data;
                }
            });
        },
        digg(item) {
            this.$http
                .get("e/public/digg/?dotop=1", { classid: item.classid, id: item.id })
                .then((res) => {
                    if (!res.code) item.diggtop = res.data;
                });
        },
        setHistory(str) {
            str = str.trim();
            const localhis = this.getHistory();
            const his = [str];
            let len = Math.min(localhis.length, 20);
            for (let i = 0; i < len; i++) {
                const item = localhis[i];
                if (item.trim() && his.indexOf(item) === -1) his.push(item);
            }
            localStorage.setItem("his", JSON.stringify(his));
        },
        getHistory() {
            const his = localStorage.getItem("his");
            console.log('history:', his)
            return his ? JSON.parse(his) : [];
        },
        clrHistory() {
            this.$dialog
                .confirm({ message: "清空搜索记录?" })
                .then(() => {
                    localStorage.removeItem("his");
                })
                .catch(() => { });
        },
        doAddToCart(info) {
            this.$http
                .post("e/ShopSys/?do=addtocart&pn=1", {
                    id: info.id,
                    classid: info.classid,
                })
                .then((res) => {
                    if (res.code === 0) {
                        this.$store.commit("setCart", res.data);
                        this.$dialog
                            .confirm({
                                message: "商品已成功加入购物车\n是否现在结算？",
                                confirmButtonText: "去购物车结算",
                                confirmButtonColor: "#07c160",
                                cancelButtonText: "继续选购",
                                center: true,
                                roundButton: true,
                            })
                            .then(() => {
                                this.$router.push({ name: "flow" });
                            })
                            .catch(() => { });
                    }
                });
        },
        /**
         * 动画垂直滚动到页面指定位置
         */
        backtop() {
            var timer = setInterval(function () {
                let osTop =
                    document.documentElement.scrollTop || document.body.scrollTop;
                let ispeed = Math.floor(-osTop / 10);
                document.documentElement.scrollTop = document.body.scrollTop =
                    osTop + ispeed;
                this.isTop = true;
                if (osTop === 0) {
                    clearInterval(timer);
                }
            }, 5);
        },
        wxReady(wx, shareInfo) {
            wx.ready(() => {
                var shareUrl = window.location.href;
                var obj = {
                    //朋友圈
                    title: shareInfo.title, // 分享标题
                    desc: shareInfo.desc, // 分享描述
                    link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    // 该链接是重定向链接，因为需要获取用户code，但是该链接又无法直接写微信获取code的链接，
                    // 所以需要点击后重新加载新的页面，来实现重定向，重新打开获取code的微信链接，实现获取用户信息的功能；
                    imgUrl: shareInfo.imgUrl, // 分享图标
                    type: shareInfo.type || "", // 分享类型,music、video或link，不填默认为link
                    dataUrl: shareInfo.dataUrl || "", // 如果type是music或video，则要提供数据链接，默认为空
                    success: function () {
                        // 用户确认分享后执行的回调函数
                        this.$toast.success("分享成功");
                    },
                    cancel: function () {
                        // 用户取消分享后执行的回调函数
                        this.$toast.fail("分享失败");
                    },
                };
                var obj1 = {
                    //好友
                    title: shareInfo.title, // 分享标题
                    desc: shareInfo.desc, // 分享描述
                    link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    // 该链接是重定向链接，因为需要获取用户code，但是该链接又无法直接写微信获取code的链接，
                    // 所以需要点击后重新加载新的页面，来实现重定向，重新打开获取code的微信链接，实现获取用户信息的功能；
                    imgUrl: shareInfo.imgUrl, // 分享图标
                    type: shareInfo.type || "", // 分享类型,music、video或link，不填默认为link
                    dataUrl: shareInfo.dataUrl || "", // 如果type是music或video，则要提供数据链接，默认为空
                    success: function () {
                        // 用户确认分享后执行的回调函数
                        this.$toast.success("分享成功");
                    },
                    cancel: function () {
                        // 用户取消分享后执行的回调函数
                        this.$toast.fail("分享失败");
                    },
                };
                // 2.1 监听“分享给朋友”，按钮点击、自定义分享内容及分享结果接口
                wx.onMenuShareAppMessage(obj1);

                // 2.2 监听“分享到朋友圈”按钮点击、自定义分享内容及分享结果接口
                wx.onMenuShareTimeline(obj);

                // 2.3 监听“分享到QQ”按钮点击、自定义分享内容及分享结果接口
                wx.onMenuShareQQ(obj1);

                // 2.4 监听“分享到微博”按钮点击、自定义分享内容及分享结果接口
                wx.onMenuShareWeibo(obj);

                // 2.5 监听“分享到QZone”按钮点击、自定义分享内容及分享接口
                wx.onMenuShareQZone(obj);
            });
        },
        doLogout() {
            return new Promise(resolve => {
                this.$http.post('logout').then(res => {
                    this.$store.commit('setUser');
                    resolve();
                })
            })
        }
    },
};

export const listMixin = {
    data() {
        return {
            scroll: 0,
        };
    },
    methods: {
        handleScroll() {
            this.scroll =
                document.documentElement && document.documentElement.scrollTop;
        },
    },
    activated() {
        if (this.scroll > 0) {
            window.scrollTo(0, this.scroll);
            this.scroll = 0;
        }
        window.addEventListener("scroll", this.handleScroll);
    },
    deactivated() {
        window.removeEventListener("scroll", this.handleScroll);
    },
};