import http from './http'

export const setToken = (token) => {
    localStorage.setItem('user_token', token);
}

export const getToken = () => {
    return localStorage.getItem('user_token') || '';
}

export const forEach = (arr, fn) => {
    if (!arr.length || !fn) return
    let i = -1
    let len = arr.length
    while (++i < len) {
        let item = arr[i]
        fn(item, i, arr)
    }
}

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的交集, 两个数组的元素为数值或字符串
 */
export const getIntersection = (arr1, arr2) => {
    let len = Math.min(arr1.length, arr2.length)
    let i = -1
    let res = []
    while (++i < len) {
        const item = arr2[i]
        if (arr1.indexOf(item) > -1) res.push(item)
    }
    return res
}

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @description 得到两个数组的并集, 两个数组的元素为数值或字符串
 */
export const getUnion = (arr1, arr2) => {
    return Array.from(new Set([...arr1, ...arr2]))
}

/**
 * @param {Array} target 目标数组
 * @param {Array} arr 需要查询的数组
 * @description 判断要查询的数组是否至少有一个元素包含在目标数组中
 */
export const hasOneOf = (targetarr, arr) => {
    return targetarr.some(_ => arr.indexOf(_) > -1)
}

/**
 * @param {String|Number} value 要验证的字符串或数值
 * @param {*} validList 用来验证的列表
 */
export function oneOf(value, validList) {
    for (let i = 0; i < validList.length; i++) {
        if (value === validList[i]) {
            return true
        }
    }
    return false
}

/**
 * @param {Number} timeStamp 判断时间戳格式是否是毫秒
 * @returns {Boolean}
 */
const isMillisecond = timeStamp => {
    const timeStr = String(timeStamp)
    return timeStr.length > 10
}

/**
 * @param {Number} timeStamp 传入的时间戳
 * @param {Number} currentTime 当前时间时间戳
 * @returns {Boolean} 传入的时间戳是否早于当前时间戳
 */
const isEarly = (timeStamp, currentTime) => {
    return timeStamp < currentTime
}

/**
 * @param {Number} num 数值
 * @returns {String} 处理后的字符串
 * @description 如果传入的数值小于10，即位数只有1位，则在前面补充0
 */
const getHandledValue = num => {
    return num < 10 ? '0' + num : num
}

/**
 * @param {Number} timeStamp 传入的时间戳
 * @param {Number} startType 要返回的时间字符串的格式类型，传入'year'则返回年开头的完整时间
 */
const getDate = (timeStamp, startType) => {
    const d = new Date(timeStamp * 1000)
    const year = d.getFullYear()
    const month = getHandledValue(d.getMonth() + 1)
    const date = getHandledValue(d.getDate())
    const hours = getHandledValue(d.getHours())
    const minutes = getHandledValue(d.getMinutes())
    const second = getHandledValue(d.getSeconds())
    let resStr = ''
    if (startType === 'year') resStr = year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + second
    else resStr = month + '-' + date + ' ' + hours + ':' + minutes
    return resStr
}

/**
 * @param {String|Number} timeStamp 时间戳
 * @returns {String} 相对时间字符串
 */
export const getRelativeTime = timeStamp => {
    // 判断当前传入的时间戳是秒格式还是毫秒
    const IS_MILLISECOND = isMillisecond(timeStamp)
        // 如果是毫秒格式则转为秒格式
    if (IS_MILLISECOND) Math.floor(timeStamp /= 1000)
        // 传入的时间戳可以是数值或字符串类型，这里统一转为数值类型
    timeStamp = Number(timeStamp)
        // 获取当前时间时间戳
    const currentTime = Math.floor(Date.parse(new Date()) / 1000)
        // 判断传入时间戳是否早于当前时间戳
    const IS_EARLY = isEarly(timeStamp, currentTime)
        // 获取两个时间戳差值
    let diff = currentTime - timeStamp
        // 如果IS_EARLY为false则差值取反
    if (!IS_EARLY) diff = -diff
    let resStr = ''
    const dirStr = IS_EARLY ? '前' : '后'
        // 少于等于59秒
    if (diff <= 59) resStr = diff + '秒' + dirStr
        // 多于59秒，少于等于59分钟59秒
    else if (diff > 59 && diff <= 3599) resStr = Math.floor(diff / 60) + '分钟' + dirStr
        // 多于59分钟59秒，少于等于23小时59分钟59秒
    else if (diff > 3599 && diff <= 86399) resStr = Math.floor(diff / 3600) + '小时' + dirStr
        // 多于23小时59分钟59秒，少于等于29天59分钟59秒
    else if (diff > 86399 && diff <= 2623859) resStr = Math.floor(diff / 86400) + '天' + dirStr
        // 多于29天59分钟59秒，少于364天23小时59分钟59秒，且传入的时间戳早于当前
    else if (diff > 2623859 && diff <= 31567859 && IS_EARLY) resStr = getDate(timeStamp)
    else resStr = getDate(timeStamp, 'year')
    return resStr
}

/**
 * @returns {String} 当前浏览器名称
 */
export const getExplorer = () => {
        const ua = window.navigator.userAgent
        const isExplorer = (exp) => {
            return ua.indexOf(exp) > -1
        }
        if (isExplorer('MSIE')) return 'IE'
        else if (isExplorer('MicroMessenger')) return 'MicroMessenger'
        else if (isExplorer('Firefox')) return 'Firefox'
        else if (isExplorer('Chrome')) return 'Chrome'
        else if (isExplorer('Opera')) return 'Opera'
        else if (isExplorer('Safari')) return 'Safari'
    }
    /**
     * @returns {Boolean} 是/否微信端或支付宝端
     * userAgent: 
     * alipayclient 支付宝
     * micromessenger 微信
     */
export const isWexin = () => {
        var ua = window.navigator.userAgent.toLowerCase();
        return /micromessenger/.test(ua) || /alipayclient/.test(ua)
    }
    /**
     * @description 绑定事件 on(element, event, handler)
     */
export const on = (function() {
    if (document.addEventListener) {
        return function(element, event, handler) {
            if (element && event && handler) {
                element.addEventListener(event, handler, false)
            }
        }
    } else {
        return function(element, event, handler) {
            if (element && event && handler) {
                element.attachEvent('on' + event, handler)
            }
        }
    }
})()

/**
 * @description 解绑事件 off(element, event, handler)
 */
export const off = (function() {
    if (document.removeEventListener) {
        return function(element, event, handler) {
            if (element && event) {
                element.removeEventListener(event, handler, false)
            }
        }
    } else {
        return function(element, event, handler) {
            if (element && event) {
                element.detachEvent('on' + event, handler)
            }
        }
    }
})()

/**
 * 判断一个对象是否存在key，如果传入第二个参数key，则是判断这个obj对象是否存在key这个属性
 * 如果没有传入key这个参数，则判断obj对象是否有键值对
 */
export const hasKey = (obj, key) => {
    if (key) return key in obj
    else {
        let keysArr = Object.keys(obj)
        return keysArr.length
    }
}

/**
 * @param {*} obj1 对象
 * @param {*} obj2 对象
 * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
 */
export const objEqual = (obj1, obj2) => {
    const keysArr1 = Object.keys(obj1)
    const keysArr2 = Object.keys(obj2)
    if (keysArr1.length !== keysArr2.length) return false
    else if (keysArr1.length === 0 && keysArr2.length === 0) return true
        /* eslint-disable-next-line */
    else return !keysArr1.some(key => obj1[key] != obj2[key])
}

// 对比两个对象是否相同, 返回True表示相同, 返回False表示不同
export const objectCompare = (obj1, obj2) => {
    let props1 = Object.keys(obj1)
    let props2 = Object.keys(obj2)

    if (props1.length !== props2.length) {
        return false
    }

    for (let i = 0, len = props1.length; i < len; i++) {
        let propName = props1[i]
        if (obj1[propName] !== obj2[propName]) {
            return false
        }
    }

    return true
}

export const bodyClick = () => {
    document.getElementsByTagName('body')[0].click()
}

export const elementSelect = (el) => {
    setTimeout(() => {
        document.getElementById(el).select()
    }, 1)
}

export const elementFocus = (el) => {
        setTimeout(() => {
            document.getElementById(el).focus()
        })
    }
    // 将时间戳从 毫秒转成秒
export const timestamp = (date) => {
    return new Date(date).getTime() / 1000
}

// 将图片src 转为缩略图样式
export const thumbStyle = (src) => {
    return `height: 250px;
          border-radius: 2px;
          border: 1px solid #dcdee2;
          background-color: #ffffff;
          background-size: 100% auto;
          background-repeat: no-repeat;
          background-position: 50% 20%;
          background-image: url(${src})`
}

// 去掉字符串两头的空格
export const trim = (str) => {
    return str.replace(/(^\s*)|(\s*$)/g, '')
}

// 去掉数组内的指定元素
export const arrayRemoveOne = (arr, item) => {
    let index = arr.indexOf(item)
    if (index > -1) {
        arr.splice(index, 1)
        return arr
    }
}

// 将数值转为文件大小 传入数值类型，得到 G,M字符串
export const byteFormat = (bytes) => {
    if (bytes === 0) return '0 B'
    let k = 1024 // or 1024
    let sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let i = Math.floor(Math.log(bytes) / Math.log(k))
    return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i]
}

export const getQueryParam = (param) => {
        const query = window.location.search.substring(1);
        if (!query.length) return false;
        const vars = query.split("&");
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            if (pair[0] == param) { return pair[1]; }
        }
        return (false);
    }
    // 获取一个url链接的参数，传key表示获取对应的键值
export const getUrlParam = (url, key) => {
        if (url.indexOf('?') == -1) return null;
        let paramstr = url.split('?')
        if (!paramstr[1] || !paramstr[1].length) return null;
        const vars = paramstr[1].split("&");
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split("=");
            if (pair[0] == key) { return pair[1]; }
        }
        return (false);
    }
    // 判断是否邮箱
export const isEmailAvailable = (email) => {
    var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    return reg.test(email);
}

// 判断是否为手机号
export const isPhoneAvailable = (phone) => {
    var myreg = /^[1][0-9]{10}$/;
    if (!myreg.test(phone)) {
        return false;
    } else {
        return true;
    }
}

// 判断是否为电话号码
export const isTelAvailable = (tel) => {
        var myreg = /^(([0\+]\d{2,3}-)?(0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/;
        if (!myreg.test(tel)) {
            return false;
        } else {
            return true;
        }
    }
    // 判断当前是否手机端访问
export const isMoblie = () => {
        let userAgentInfo = navigator.userAgent;
        let Agents = ["Android", "iPhone",
            "SymbianOS", "Windows Phone",
            "iPad", "iPod"
        ];
        let flag = false;
        for (let v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > -1) {
                flag = true;
                break;
            }
        }
        return flag;
    }
    /**
     * 是否有浏览器浏览记录, 用于判断登录后返回上一页还是首页
     * @return true/false 
     */
export const hasHistory = () => {
    if ((navigator.userAgent.indexOf('MSIE') >= 0) && (navigator.userAgent.indexOf('Opera') < 0)) { // IE
        return history.length > 0;
    } else { //非IE浏览器
        if (navigator.userAgent.indexOf('Firefox') >= 0 ||
            navigator.userAgent.indexOf('Opera') >= 0 ||
            navigator.userAgent.indexOf('Safari') >= 0 ||
            navigator.userAgent.indexOf('Chrome') >= 0 ||
            navigator.userAgent.indexOf('WebKit') >= 0) {
            return window.history.length > 1;
        } else { //未知的浏览器
            return true;
        }
    }
}

export const weixinInit = (next) => {
    const code = getQueryParam('code');
    const openid = getQueryParam('openid');
    const wxcode = localStorage.getItem('wxcode');
    const wxopenid = localStorage.getItem('wxopenid');
    const route = getQueryParam('state');
    if (code && wxcode !== code) {
        localStorage.setItem('wxcode', code); // 记录下code值，防止造成code失效错误
        http.get('e/memberconnect/?apptype=wxmp', { code }).then(res => {
            if (res.code === 0) {
                next({ name: route })
            }
        })
        return false;
    }
    if (openid && wxopenid !== openid) {
        localStorage.setItem('wxopenid', openid);
        http.get('e/memberconnect/?apptype=payjs', { openid }).then(res => {
            if (res.code === 0) {
                next({ name: route })
            }
        })
        return false;
    }
    if (route && !code && !openid) {
        next({ name: route })
    }
}